
<template>
  <vc-datasource-custom
      name="landingSite"
      :show="true">
    <vc-entity
        v-for="(item,index) in landingSitePositionInfoList"
        :id="'landingSite_'+item.id.toString()+'_'+uuid()"
        :key="index"
        :position="[item.longitude,item.latitude, 0]"
        description="landingSite"
    >
      <!-- <vc-graphics-point ></vc-graphics-point> -->
      <vc-graphics-point
          :color="'#09fd03'"
          :pixel-size="10"
      />
    </vc-entity>
  </vc-datasource-custom>
</template>

<script >
import {uuid} from "@/js/utils";

export default {
  name:'LandingSite',
  methods: {uuid},
  props:['landingSitePositionInfoList'],
  data() {
    return {}
  }
}
</script>

<style scoped>

</style>