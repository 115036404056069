<template>
  <el-dialog
    v-model="ImgDisplayVisible"
    title="实地图片"
    width="50%"
  >
    <div>
      <el-select
          @change="change"
        v-model="option"
        style="margin-top: 0;margin-bottom: 20px"
      >
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        />
      </el-select>
      <el-carousel :height="bannerHeight + 'px'">
        <el-carousel-item
          v-for="item in imgsUrl"
          :key="item"
        >
          <div style="">
            <img
              :src="item.url"
              class="bannerimg"
            >
            <!--          <el-image :src="item.url" class="bannerimg" lazy></el-image>-->
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: "ImgDisplay",
  props:['type','imgsUrl'],
  emits:['changeImgsUrl'],
  data(){
    return{
      loading:true,
      bannerHeight: 200,
      ImgDisplayVisible:false,
      option:'hangpai',
      options:[
        {
          label:'航拍图',
          value:'hangpai'
        },
        {
          label:'实拍图',
          value:'shipai'
        }
      ]
    }
  },
  mounted(){
    this.setSize();
    window.addEventListener('resize', ()=>{
      this.setSize();
    },false);
  },
  methods:{
    change(val){
      this.$emit('changeImgsUrl',(val))
    },
    setSize(){
      this.bannerHeight = document.body.clientWidth / 4
    }
  }
}
</script>

<style scoped>
.bannerimg{
  width: 100%;
  height: inherit;
}
</style>