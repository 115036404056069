
import {http}  from "../httpConfig";
// import { ElMessage } from "element-plus"

//地震id获取地震详细信息(详细框用)
function getDetailEarthquakeInfoById(params) {
    return http.get('/earthquakeInfo/getEarthquakeInfoById',{'earthquakeId':params}).then(response=>{
        const earthquakeInfo=response.data
        const detailInfo=[
        // {
        //   key:'地震编号',
        //   value:earthquakeInfo.earthquakeId
        // },
        {
          key:'地震名称',
          value: earthquakeInfo.earthquakeName,
        },
        {
          key:'震级',
          value: earthquakeInfo.magnitude,
        },
        {
          key:'震源经纬度',
          value: earthquakeInfo.longitude+','+earthquakeInfo.latitude,
        },
        {
          key:'震中烈度',
          value: earthquakeInfo.highIntensity,
        },
        {
          key:'发生时间',
          value: earthquakeInfo.earthquakeTime,
        },]
        return detailInfo
    })
}
function getAllLandingSite() {
        return http.get('/landingSite/getAll','')
            .then(response=>{return response.data})
}
function getLandSiteById(id) {
    return http.get('/landingSite/getById',{'id':id})
        .then(response=>{
            response=response.data
            return [
                {
                    key: '名称',
                    value: '名称_' + response.id,
                },
                {
                    key: '位置',
                    value: '(' + response.longitude + ',' + response.latitude + ')',
                },
            ]})
}
function getAllMeteoriteCrater() {
    return http.get('/meteoriteCrater/getAll','')
        .then(response=>{return response.data})
}
function getMeteoriteCraterById(id) {
    return http.get('/meteoriteCrater/getById',{'id':id})
        .then(response=>{
            response=response.data
            return [
            {
                key:'名称',
                value:'名称_'+response.id,
            },
            {
                key:'位置',
                value:'('+response.longitude+','+response.latitude+')',
            },
            {
                key:'坡度角度',
                value:response.angle
            }
        ]})
}
export {
    getDetailEarthquakeInfoById,
    getAllLandingSite,
    getLandSiteById,
    getMeteoriteCraterById,
    getAllMeteoriteCrater
}