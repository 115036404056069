
import router from './router'
// main.ts
import { createApp } from 'vue'
import VueCesium from 'vue-cesium'
import App from './App.vue'

import 'vue-cesium/dist/index.css'

import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
const app = createApp(App)

app.use(router) // 将默认使用 https://unpkg.com/cesium@latest/Build/Cesium/Cesium.js

// 由于Cesium更新可能有破坏性更新，建议在生产环境中锁定 Cesium 版本
app.use(VueCesium, {
  cesiumPath: 'https://cesium.com/downloads/cesiumjs/releases/1.91/Build/Cesium/Cesium.js',
  accessToken: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiI5MWVhZmFlYS1mYWU2LTQ2ZDQtOTk5ZS03ZGE3MjQyM2FlODYiLCJpZCI6NjE3MjEsImlhdCI6MTYyNjMxMzk3NX0.bdlFAJG5w5iqwdFiFxPfW_h4H5nElCoyqIY0WjUcBvU'
  //'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiI2ZDY5MzYwZS1jZWU3LTQ0MzYtOGQ1Yi00NzY3MjYxZTIwNjAiLCJpZCI6MTYxMzcxLCJpYXQiOjE2OTI0NTIyNjZ9._gVbbqRZpicMQ8Jxe06ghQjZIj_SKCCz8pW_B_fAZtI'
}).use(ElementPlus)

app.mount('#app')