
<template>
  <vc-datasource-custom
      name="meteoriteCrater"
      :show="true">
    <vc-entity
        v-for="(item,index) in meteoriteCraterPositionInfoList"
        :id="'meteoriteCrater_'+item.id.toString()+'_'+uuid()"
        :key="index"
        :position="[item.longitude,item.latitude, 0]"
        description="meteoriteCrater"
    >
                <vc-graphics-point
                    :color="'#ff2626'"
                    :pixel-size="10"
                />
      <vc-graphics-ellipse
          :semi-minor-axis="item.radius*10000"
          :semi-major-axis="item.radius*10000"
          :material="[255, 30, 0, 125]"
          :fill="true"
      />
    </vc-entity>
  </vc-datasource-custom>
</template>

<script>
import {uuid} from "@/js/utils";

export default {
  name: "MeteoriteCrater",
  methods: {uuid},
  props:['meteoriteCraterPositionInfoList'],
  data(){
    return{
      meteoriteCraterPositionInfoList1:[
        {
          "id": 1,
          "longitude": 112.273583,
          "latitude": 26.236499,
          "angle": 4.22922,
          "radius":1.2,
          "name": null
        },
        {
          "id": 2,
          "longitude": 112.730713,
          "latitude": 26.227580,
          "angle": 1.94219,
          "radius":1,
          "name": null
        },
        {
          "id": 3,
          "longitude": 112.671615,
          "latitude": 27.22224,
          "angle": 3.84914,
          "radius":2,
          "name": null
        },
        {
          "id": 4,
          "longitude": 112.102951,
          "latitude": 25.765125,
          "angle": 2.36359,
          "radius":2.1,
          "name": null
        },
        {
          "id": 5,
          "longitude": 113.398426,
          "latitude": 24.781538,
          "angle": 2.14322,
          "radius":4.8,
          "name": null
        },]
    }
  }
}

</script>
<style scoped>

</style>