
<template>
      <div v-if="detailVisible" class="detail-box">
      <div style="display: flex;justify-content: space-between;align-items: center;padding: 3px">
        <h2 style="color: black;margin: 0">
          {{ title }}
        </h2>
        <div>
          <el-button
              @click="$refs.img.ImgDisplayVisible=true;changeImgsUrl('hangpai');$refs.img.option='hangpai'"
          >
            实地图片
          </el-button>
          <el-button
              @click="$emit('cameraTo',detailInfo[1].value.split(',')[0].split('(')[1],detailInfo[1].value.split(',')[1].split(')')[0],100000)"
          >
            直达现场
          </el-button>
<!--          <el-button  icon="el-icon-search">搜索</el-button>-->
        </div>
      </div>
      <el-table :data="detailInfo"
      :show-header="false">
        <el-table-column property="key" label="key" width="150" />
        <el-table-column property="value" label="value" width="200" />
      </el-table>
      </div>
  <ImgDisplay ref="img"
              :imgs-url="imgsUrl"
              @change-imgs-url="changeImgsUrl"
              :type="type"/>
</template>
<script >
import ImgDisplay from "@/components/ImgDisplay.vue";
import {uuid} from "@/js/utils"
export default {
  name : 'Detail',
  components: {ImgDisplay},
  props:['detailVisible','detailInfo','type'],
  emits:['onClose','cameraTo'],
  data () {
    return{
      imgsUrl:[],
      title:'详情',
      detailTableVisible:true,
    }
  },
  methods:{
    changeImgsUrl(option){
      if(option==='hangpai') this.imgsUrl=
          [
            {url:'https://www.file.zouran.top/imgs/mars/'+this.type+'/fly_1.jpg'},
          ]
      if(option==='shipai') this.imgsUrl=
          [
            {url:'https://www.file.zouran.top/imgs/mars/'+this.type+'/load_1.jpg'},
          ]
    }
  }
}
</script>
<style >
.detail-box{
  padding: 9px 13px 14px 13px;
  position: absolute;
  bottom: 10%;
  right: 30px;
  color: #fff!important;
  background: rgba(255,255,255,100%);
  width: 450px;
  z-index: 999;

  border-radius: 8px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);
}
</style>