import {createRouter, createWebHistory} from 'vue-router'
import Cesium from "@/components/Cesium.vue";
const routes = [
  {
    path: '/',
    name: 'home',
    component: Cesium
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
