<template>
  <Detail
      :type="type"
      :detailVisible="detailVisible"
      :detail-info="detailInfo"
      @on-close="onClose"
      @camera-to="cameraTo"/>
  <SeismicZones
      ref="SeismicZones"/>
  <div class="toolbar">
  <el-row >
    <el-popover
        ref="controlVisible"
        v-model:visible="lay"
        placement="bottom"
        trigger="click"
        width="50"
    >
      <template #reference>
        <el-button
            type="default"
            class=""
        >菜单</el-button>
      </template>
      <div style="text-align: left">
      <el-button
      @click="$refs.SeismicZones.SeismicZonesVisible=true">
        陨坑统计
      </el-button>
      <el-popover
          v-model:visible="visibleRoad"
          placement="bottom"
          :width="350"
      >
        <template #reference>
          <el-button
              style="margin-left: 0;margin-top: 10px"
              @click="visibleRoad = !visibleRoad"
          >
            路径规划
          </el-button>
        </template>
          <el-button
              type="primary"
              @click="selectPositionRoad()"
          >
            选取救援点
          </el-button>
          <el-button
              type="primary"
              @click="getPositionRoad()"
          >
            开始路径规划
          </el-button>
          <el-button
              type="danger"
              @click="stopPositionRoad()"
          >
            关闭
          </el-button>
      </el-popover>
      </div>
    </el-popover>
  </el-row>
  </div>
  <div class="viewer">
    <vc-viewer
        ref="vcViewer"
        @before-load="beforeLoad">
      <!-- geojson -->
      <GeoJson
        :linedata-visible="linedataVisible"/>
      <!--鼠标工具 -->
      <vc-selection-indicator @pick-evt="pickEvt"/>
<!--      着陆点-->
      <LandingSite
        :landing-site-position-info-list="landingSitePositionInfoList"/>
<!--      陨石坑-->
      <MeteoriteCrater
        :meteorite-crater-position-info-list="meteoriteCraterPositionInfoList"/>
<!--      影像服务-->
      <ImageryProvide/>
<!--      小工具-->
      <vc-navigation></vc-navigation>
<!--      测绘工具-->
<!--      <vc-measurements :offset="[0, 40]" ></vc-measurements>-->
      <Measure/>
    </vc-viewer>
  </div>
</template>

<script>
import Detail from "@/components/Detail.vue";
import SeismicZones from "@/components/SeismicZones.vue";
import {getAllLandingSite, getAllMeteoriteCrater, getLandSiteById, getMeteoriteCraterById} from "@/axios/api/CesiumApi";
import LandingSite from "@/components/cesium/LandingSite.vue";
import MeteoriteCrater from "@/components/cesium/MeteoriteCrater.vue";
import GeoJson from "@/components/cesium/GeoJson.vue";
import ImageryProvide from "@/components/cesium/ImageryProvide.vue";
import Measure from "@/components/cesium/Measure.vue";

export default {
  name: 'Cesium',
  components: {Measure, ImageryProvide, GeoJson, MeteoriteCrater, LandingSite, SeismicZones, Detail},
  data () {
    return {
      pathNum:1,
      visibleRoad:true,
      drawings:[ 'polygon'],
      type:'landingSite',
      linedataVisible:true,
      lay:true,
      detailInfo:[],
      detailVisible: false,
      startPosition:{},
      //记录列表着陆点
      landingSiteList: [],
      //着陆点信息列表
      landingSitePositionInfoList: [{
        id: 1,
        longitude: 101.87,
        latitude: 25.67,
      }],
      //陨石坑位置集合
      meteoriteCraterPositionInfoList:[{
        id: 1,
        longitude: 99.97,
        latitude: 25.67,
      }],
    }
  },
  methods: {
    selectPositionRoad(){
      this.num++;
      this.$message.success("现在可以开始选取救援点啦~");
    },
    onClose(){
      this.detailVisible=false;
      console.log('close')
    },
    cameraTo(lat,lon,height){
      let viewer=this.$refs.vcViewer.getCesiumObject();
      viewer.camera.flyTo(
          {
            destination : Cesium.Cartesian3.fromDegrees(lat,lon,height),
            orientation : {
              heading : Cesium.Math.toRadians(0.0),
              pitch : Cesium.Math.toRadians(-90.0),
              roll : 0.0
            }
          }
      )
    },
    beforeLoad(){
      getAllLandingSite()
          .then(response=>{
            this.landingSitePositionInfoList=response;
            // console.log(response)
          })
      getAllMeteoriteCrater()
          .then(response=>{
            this.meteoriteCraterPositionInfoList=response;
            // console.log(response)
          })
    },
    pickEvt(e){
      if(!e) {
        console.log("!e")
        return
      }
      try{
        // console.log('pickEvt',e)
        if(e._id==='__Vc__Pick__Location__')
        {
          if(this.pathNum!==0){
            const cartographic = Cesium.Cartographic.fromCartesian(e._position._value);
            this.startPosition['longTemp'] = Cesium.Math.toDegrees(cartographic.longitude);
            this.startPosition['latTemp'] = Cesium.Math.toDegrees(cartographic.latitude);
            this.startPosition['height'] = cartographic.height;
            this.pathNum=0
          }
          this.detailVisible=false
          //不可少
          return;
        }
        let kind=e.id.split("_")[0]
        // console.log(kind)
        let index=parseInt(e.id.split("_")[1])
        // console.log(index)
        const that=this
        //编号查询着陆点数据
        if(kind==='landingSite')
        {
          that.type=kind
          getLandSiteById(index)
              .then(response=>{
                that.detailInfo=response
                that.detailVisible=true
              })
          // console.log('id')
        }
        else if(kind==='meteoriteCrater'){
          that.type=kind
          getMeteoriteCraterById(index)
              .then(response=>{
                that.detailInfo=response
                that.detailVisible=true
              })
        }
        else
        {
          this.detailVisible=false
        }
      }
      catch (err) {
        console.log(err)
        console.log('error');
        this.detailVisible=false
      }
    },
  }
}
</script>
<style>
.toolbar {
  position: absolute;
  left: 1%;
  top: 1%;
  /*min-width: 185px;*/
  z-index: 100;
  color: #fff!important;
  background-color: rgba(0,0,0,.2);
}
.viewer {
  height: 100vh;
}
</style>
