
<template>
  <vc-layer-imagery>
<!--    <vc-imagery-provider-wmts-->
<!--        ref="provider"-->
<!--        url="http://localhost:81/{TileMatrix}/{TileCol}/{TileRow}.png"-->
<!--        layer="World_Street_Map"-->
<!--        format="image/jpeg"-->
<!--        wmtsStyle="default"-->
<!--        tileMatrixSetID="default028mm"-->
<!--    >-->
<!--    </vc-imagery-provider-wmts>-->
    <vc-imagery-provider-wms
        ref="provider"
        url="https://planetarymaps.usgs.gov/cgi-bin/mapserv?map=/maps/mars/mars_simp_cyl.map&service=WMS"
        layers="MDIM21_color"
        :parameters="{transparent: false, format: 'image/png'}"
        tileWidth="256"
        tileHeight="256"
        :enablePickFeatures="enablePickFeatures"
    ></vc-imagery-provider-wms>
  </vc-layer-imagery>
<!--  http://localhost:81/{TileMatrix}/{TileCol}/{TileRow}.png-->
<!--  https://astro.arcgis.com/arcgis/rest/services/OnMars/MDIM/MapServer/tile/{TileMatrix}/{TileCol}/{TileRow}?blankTile=false-->
<!--  https://moon.bao.ac.cn/gis3globleMarsMoon/tiles/getTiles/MarsTile/1000/jpg/{TileMatrix}/{TileRow}/{TileCol}-->
</template>

<script>
export default {
  name: "ImageryProvide",
  data(){
    return{
      enablePickFeatures:false
    }
  }
}
</script>

<style scoped>

</style>