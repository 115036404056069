<template>
  <el-dialog
    v-model="SeismicZonesVisible"
    title="区域统计"
    width="68vw"
    center
    top="2.5vh"
  >
    <div class="el-dialog-div">
      <el-collapse @change="getSeismicZonesData">
        <el-collapse-item title="测试区域">
          <p>该地区陨石坑总数:{{ earthquakeInfoList.length }}</p>
          <p>最大滑坡陨石坑名称:{{ maxMagnitude['earthquakeName'] }}</p>
          <p>经纬度:({{ maxMagnitude['longitude'] }},{{ maxMagnitude['latitude'] }})</p>
          <p>最大滑坡陨石坑名称:{{ minMagnitude['earthquakeName'] }}</p>
          <p>经纬度:({{ minMagnitude['longitude'] }},{{ minMagnitude['latitude'] }})</p>
          <p>平均滑坡:{{avg}}</p>
        </el-collapse-item>
      </el-collapse>
      <el-carousel :height="bannerHeight + 'px'">
        <el-carousel-item
          v-for="item in imgsUrl"
          :key="item"
        >
          <div style="margin-left: 25%">
            <img
              :src="item.url"
              class="bannerimg"
              alt=""
            >
            <!--          <el-image :src="item.url" class="bannerimg" lazy></el-image>-->
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
  </el-dialog>
</template>

<script>
import {selectEarthquakeList, sortByProperty, changeGeoJsonToPosition, avgSlope} from "@/js/SeismicZones"
export default {
  name: "SeismicZones",
  data(){
    return{
      imgsUrl:[
        {url:'https://www.file.zouran.top/imgs/mars/mars_1.jpg'},
        {url:'https://www.file.zouran.top/imgs/mars/mars_2.jpg'},
      ],
      sendFlag:1,
      bannerHeight: 200,
      earthquakeInfoList:[],
      maxMagnitude:{},
      minMagnitude:{},
      SeismicZonesVisible:false,
      avg:5,
    }
  },
  mounted(){
    this.setSize();
    window.addEventListener('resize', ()=>{
      this.setSize();
    },false);
  },
  methods:{
    getSeismicZonesData(){
      if(this.sendFlag===0) {
        this.sendFlag=1
        return;
      }
      this.sendFlag=0
      const that=this
      const position=changeGeoJsonToPosition()
      // that.axios.get("earthquakeInfo/getSeismicZonesData?"+'left='+position.left+'&right='+position.right+'&top='+position.top+'&bottom='+position.bottom
            const data=[{
              meteoriteCraterId: 1,
              longitude: 99.97,
              latitude: 25.67,
              magnitude:4,
            },{
              meteoriteCraterId: 2,
              longitude: 99.87,
              latitude: 25.67,
              magnitude:6,

            },{
              meteoriteCraterId: 3,
              longitude: 99.87,
              magnitude:5,
              latitude: 25.37,
            },{
              meteoriteCraterId: 4,
              longitude: 100.87,
              magnitude:6,
              latitude: 25.67,
            },{
              meteoriteCraterId: 5,
              longitude: 100.37,
              magnitude:4.6,
              latitude: 25.62,
            },]
            const earthquakeList=selectEarthquakeList(data)
            that.earthquakeInfoList=sortByProperty(earthquakeList,'magnitude')
            that.maxMagnitude=that.earthquakeInfoList[that.earthquakeInfoList.length-1]
            that.minMagnitude=that.earthquakeInfoList[0]
            that.avg=avgSlope(earthquakeList)
    },
    setSize(){
      this.bannerHeight = document.body.clientWidth / 4
    }
  }
}
</script>

<style scoped>
.el-dialog-div{
  height: 70vh;
  overflow: auto;
  padding: 10px;
}
.bannerimg{
  width: 70%;
  height: inherit;
}
</style>