
<template>
  <vc-drawings
      @draw-evt="drawEvt"
      :drawings="drawings"
      position="bottom-left"
      ref="drawingsRef">
  </vc-drawings>
</template>

<script>
export default {
  name: "Measure",
  data(){
    return{
      drawings:[ 'polygon'],
    }
  },
  methods:{
    drawEvt(evt){
      if(evt.finished===true) {
        let positions=[]
        switch (evt.name) {
          case 'polygon':{
            for (let i = 0; i < evt.points.length; i++) {
              let cartographic = Cesium.Cartographic.fromCartesian(evt.points[i].position);
              let longTemp = Cesium.Math.toDegrees(cartographic.longitude);
              let latiTemp = Cesium.Math.toDegrees(cartographic.latitude);
              const position=[longTemp,latiTemp]
              positions.push(position)
            }
            positions.push(evt.points[0])
          }
            console.log(positions)
        }
      }
    },
  }
}
</script>
<style scoped>

</style>