
<template>
  <vc-datasource-geojson
      ref="ds"
      data="https://www.file.zouran.top/json/YunNan.json"
      :show="linedataVisible"
      :fill="options.fill"
      :stroke="options.stroke"
  />
</template>

<script >
export default {
  name :'GeoJson',
  props:['linedataVisible'],
  data(){
    return{
      //边界线选项
      options: {
        fill:[0,0,0,0.1],
        stroke: 'yellow'
      },
    }
  }
}
</script>

<style scoped>

</style>